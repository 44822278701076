import React from "react"
import styled from "styled-components"

import { Row, Col } from "react-bootstrap"
import ai from "../../assets/images/artificial-intelligence.svg"

const SmartModulesBox = () => {
  return (
    <Row
      className="g-0 mx-auto"
      style={{
        borderColor: "rgb(27 98 194)",
        boxShadow: "0 44px 98px rgb(0 0 0 / 12%)",
        borderBottomStyle: "solid",
        padding: "20px",
      }}
    >
      <Col md={12} lg={6} className="text-center">
        <Styledh2>Smart Modules</Styledh2>
        <Styledp>
          azszero Understands Amazon FBA Business, It will save you a tremendous
          amount of time guessing and managing your Amazon stores by showing you
          what you need to see, Alerting you of What you need to know at that
          moment, and Telling you What to do next. It’s like hiring a
          professional Amazon seller that watches your store and gives you
          guidelines, and he does it 24/7!
        </Styledp>
      </Col>
      <Col md={12} lg={6} className="text-center">
        <ImgStyled src={ai} alt="" />
      </Col>
    </Row>
  )
}

const ImgStyled = styled.img`
  width: 250px;
  height: 250px;
`

const Styledh2 = styled.h2`
  font-weight: 700;
  font-size: 35px;
  color: #535b7c !important;
  line-height: 1.3em;
`

const Styledp = styled.p`
  line-height: 1.8em;
  font-size: 18px;
`
export default SmartModulesBox

import React from "react"
import styled from "styled-components"

import { Row, Col } from "react-bootstrap"
import ai from "../../assets/images/mind.svg"

const Concentrate = () => {
  return (
    <Row
      className="g-0 mx-auto"
      style={{
        borderColor: "rgb(27 98 194)",
        boxShadow: "0 44px 98px rgb(0 0 0 / 12%)",
        borderBottomStyle: "solid",
        padding: "20px",
      }}
    >
      <Col md={12} lg={6} className="text-center">
        <ImgStyled src={ai} alt="" />
      </Col>

      <Col md={12} lg={6} className="text-center">
        <h2>Concentrate on What Matters Most</h2>
        <p>
          Grow your Amazon FBA Business at a pace you never thought was possible,
          Think of azszero as a Full Team of Experts working just for you,
          leaving you to build more ASINs and source more Nitches without losing
          precious time in any management.
        </p>
      </Col>
    </Row>
  )
}
const ImgStyled = styled.img`
  width: 250px;
  height: 250px;
`
export default Concentrate

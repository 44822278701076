import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"
const Ifyouhave = () => {
  return (
    <>
      <Container>
        <Styledh1 className="display-4">Do I Need azszero?</Styledh1>
        <h5>
          If you ever encountered any of the following, the answer is YES.
        </h5>
        <Row className="g-0 mx-auto">
          <Col sm={12} md={6} lg={4} className="p-3">
            <StyledQuotes>
              <Styledh5> Had ASINs went out of stock</Styledh5>
            </StyledQuotes>
          </Col>
          <Col sm={12} md={6} lg={4} className="p-3">
            <StyledQuotes>
              <Styledh5>
                Not able to follow up on ASINs that have no sales today
              </Styledh5>
            </StyledQuotes>
          </Col>
          <Col sm={12} md={6} lg={4} className="p-3">
            <StyledQuotes>
              <Styledh5>Was not aware of delayed shipments</Styledh5>
            </StyledQuotes>
          </Col>
          <Col sm={12} md={6} lg={4} className="p-3">
            <StyledQuotes>
              <Styledh5>
                Had ASINs that suddenly suppressed from search results
              </Styledh5>
            </StyledQuotes>
          </Col>
          <Col sm={12} md={6} lg={4} className="p-3">
            <StyledQuotes>
              <Styledh5> Sent too many or too little stock</Styledh5>
            </StyledQuotes>
          </Col>
          <Col sm={12} md={6} lg={4} className="p-3">
            <StyledQuotes>
              <Styledh5>
                Had your IPI fallen and was not able to send new shipments
              </Styledh5>
            </StyledQuotes>
          </Col>
          <Col sm={12} md={6} lg={4} className="p-3">
            <StyledQuotes>
              <Styledh5> Had no idea why the return rate is too high</Styledh5>
            </StyledQuotes>
          </Col>
          <Col sm={12} md={6} lg={4} className="p-3">
            <StyledQuotes>
              <Styledh5>
                Found that your pricing is too high or too low
              </Styledh5>
            </StyledQuotes>
          </Col>
          <Col sm={12} md={6} lg={4} className="p-3">
            <StyledQuotes>
              <Styledh5>
                Wish you could see every ASIN's sales in a simple way
              </Styledh5>
            </StyledQuotes>
          </Col>
          <Col sm={12} md={6} lg={4} className="p-3">
            <StyledQuotes>
              <Styledh5>
                You implement sales strategy, but do not know if the effect was
                any good
              </Styledh5>
            </StyledQuotes>
          </Col>
          <Col sm={12} md={6} lg={4} className="p-3">
            <StyledQuotes>
              <Styledh5>
                You do not plan on what to do in the next few days
              </Styledh5>
            </StyledQuotes>
          </Col>
          <Col sm={12} md={6} lg={4} className="p-3">
            <StyledQuotes>
              <Styledh5>Wanted to resolve any bad feedback ASAP</Styledh5>
            </StyledQuotes>
          </Col>
          <Col sm={12} md={6} lg={4} className="p-3">
            <StyledQuotes>
              <Styledh5>
                Wanted to know if any of your ASINs are at risk of suspension
              </Styledh5>
            </StyledQuotes>
          </Col>
          <Col sm={12} md={6} lg={4} className="p-3">
            <StyledQuotes>
              <Styledh5>
                Your FBA shipments was received, but has a problem with that you
                did not know about until later
              </Styledh5>
            </StyledQuotes>
          </Col>
          <Col sm={12} md={6} lg={4} className="p-3">
            <StyledQuotes>
              <Styledh5>
                Looking for an easy and effective way to write your product page
              </Styledh5>
            </StyledQuotes>
          </Col>
        </Row>
      </Container>
    </>
  )
}

const Styledh1 = styled.h1`
  padding: 30px;
  text-align: center;
  color: slategray;
`

const StyledQuotes = styled.div`
  border: 1px solid #ddd;
  padding: 0.5em 1em 0.5em 1em;
  border-radius: 0.2em;
  height: 100% !important;
  position: relative;
  min-height: 68px;

  overflow: hidden;
`
const Styledh5 = styled.h5`
  font-size: 0.75em;
  margin: 0em 0 0.25em 0;
  line-height: 1.25em;

  &:before {
    font-size: 5em;
    font-family: "Georgia", serif;
    content: "\\2713";
    position: relative;
    left: 0em;
    top: 0.45em;
    color: #ddd;
    z-index: -1;
    opacity: 0.5;
  }
  &:after {
    font-family: "Georgia", serif;
    content: "\\2E17";
    font-size: 12em;
    position: absolute;
    right: -0.085em;
    bottom: 0.15em;
    color: #ddd;
    z-index: -1;
  }
`

export default Ifyouhave

import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Hometopcards from "../components/cards/Hometopcards"
import Homehero from "../components/HomePage/Homehero"
import SmartModulesBox from "../components/HomePage/SmartModulesBox"
import Concentrate from "../components/HomePage/Concentrate"
import DivSeperator from "../components/HomePage/DivSeperator"
import Lightsection from "../components/HomePage/Lightsection"
import Ifyouhave from "../components/HomePage/Ifyouhave"
import SectionDevider from "../components/HomePage/SectionDevider"
import styled from "styled-components"

export default function Home() {
  return (
    <Layout>
      <Seo
        title="Amazon FBA Management Software -- Home"
        description="AZSzero helps amazon sellers to manage their FBA business by providing them with critical data, tracking, and alerts on their sales, inventory and ASINs so that they can have the time to grow faster."
      />
    <HeroTop></HeroTop>
      <HeroBottom>
        <Homehero />

        {/* <div className="section1-divider"></div> */}
        <Hometopcards />
      </HeroBottom>
      <div className="container mt-0 mb-1">
        <SmartModulesBox />
        <DivSeperator />
        <Concentrate />
      </div>
      <Lightsection />

      <Ifyouhave />
      <SectionDevider />
    </Layout>
  )
}

const HeroTop = styled.div`
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIyNDNweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9InJnYmEoMCw2OSwxMzUsMC41NykiPjxwYXRoIGQ9Ik0xMjgwIDBsLTI2Mi4xIDExNi4yNmE3My4yOSA3My4yOSAwIDAgMS0zOS4wOSA2TDAgMHYxNDBoMTI4MHoiLz48L2c+PC9zdmc+);
  background-size: 100% 243px;
  top: 0;
  height: 243px;
  max-height: 300px;
  transform: rotateY(180deg) rotateX(180deg);
  background-color: transparent;
`
const HeroBottom = styled.div`
  background-image: linear-gradient(180deg, #f9fcf6bf, #1b62c2),
    url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSI1MDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiMwYzcxYzMiPjxwYXRoIGQ9Ik0xMjgwIDE0MFYwSDBsMTI4MCAxNDB6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 100% 500px;
  top: 0;
  margin-top: -243px;
`

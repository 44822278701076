import React from "react"
import styled from "styled-components"

const SectionDevider = () => {
  return (
    <>
      <Divider1Styled></Divider1Styled>
      <Devider2Styled></Devider2Styled>
    </>
  )
}

const Divider1Styled = styled.div`
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiMwYzcxYzMiPjxwYXRoIGQ9Ik02NDAgMTM5TDAgMHYxNDBoMTI4MFYwTDY0MCAxMzl6IiBmaWxsLW9wYWNpdHk9Ii41Ii8+PHBhdGggZD0iTTY0MCAxMzlMMCA0MnY5OGgxMjgwVjQybC02NDAgOTd6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 100% 100px;
  bottom: 0;
  height: 100px;
  z-index: 1;
`
const Devider2Styled = styled.div`
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTAwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBzbGljZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMGM3MWMzIj48cGF0aCBkPSJNNzIwIDE0MEw2NDAgMGwtODAgMTQwSDBWMGgxMjgwdjE0MEg3MjB6Ii8+PC9nPjwvc3ZnPg==);
  background-size: cover;
  background-position-x: center;
  top: 0;
  height: 41px;
  z-index: 1;
`
export default SectionDevider
